<template>
  <a-spin :spinning="loading">
    <div class="personal-picStore">
      <div class="pic-line">
        <div class="picture-card" v-for="pic in picList" :key="pic.id">
          <div
            class="img-wrap"
            :style="{ 'background-image': `url(${pic.preview})` }"
            @click="handlePicPreview([pic.url])"
          ></div>
          <div class="btn-line">
            <a-tooltip title="下载">
              <a-icon type="download" @click="handleDownload(pic)" />
            </a-tooltip>
            <a-divider type="vertical" />
            <a-tooltip title="编辑">
              <a-icon type="edit" @click="jumpEditImage(pic.url, pic.fileId)" />
            </a-tooltip>
            <a-divider type="vertical" />
            <a-tooltip title="删除" placement="topRight">
              <a-popconfirm title="确定删除该图片吗?" @confirm="handleDeletePicture(pic.id)">
                <a-icon type="delete" @click="() => {}" />
              </a-popconfirm>
            </a-tooltip>
          </div>
        </div>
      </div>
      <div v-show="!picList.length" class="tips">暂无数据</div>
      <base-pagination
        :currentPage="pagination.current"
        :pageSize="pagination.pageSize"
        :options="['20', '50', '100']"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>
  </a-spin>
</template>

<script>
import { api as viewerApi } from 'v-viewer';
import api from './api.js';

export default {
  name: 'personalPicStore',
  data() {
    return {
      picList: [],
      loading: false,
      pagination: {
        current: 1,
        pageSize: 20,
        total: 0,
      },
    };
  },
  created() {
    this.getPicList();
  },
  methods: {
    getPicList() {
      this.loading = true;
      api
        .getMyPicStoreList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
        })
        .then(({ code, data, message }) => {
          if (code === 200) {
            // 小红书特殊处理
            data.list.forEach((item) => {
              if (item.preview.includes('xhscdn')) {
                item.preview = `/content-center/image/show.png?image=` + item.preview.replace('http:', 'https:');
              }
            });

            this.picList = data.list;
            this.pagination.total = data.total;
          } else {
            this.$message.error(message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePicPreview(images) {
      const $viewer = this.$viewerApi({
        options: {
          toolbar: true,
          index: 0,
        },
        images,
      });
    },
    jumpEditImage(fileUrl, fileId) {
      let imageUrlList = [fileUrl];
      api.saveImageList({ configType: 'imageEdit', data: { imageUrlList } }).then(({ data }) => {
        window.open(`${process.env.VUE_APP_TEMPLATE_EDITOR}?localId=${data}&fileId=${fileId}`, '_blank');
      });
    },
    handleDownload({ url, id, fileId }) {
      if (url.includes('xhscdn')) {
        url = `/content-center/image/show.png?image=` + url.replace('http:', 'https:');
      }
      const imgUrl = `${url}?time=${Date.now()}`;
      const title = id || '图片';
      const hide = this.$message.loading('正在获取图片...', 0);
      fetch(imgUrl)
        .then(async (res) => {
          res
            .blob()
            .then((blob) => {
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = URL.createObjectURL(blob);
              a.download = title;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            })
            .catch(() => {
              window.open(url, '_blank');
            });
        })
        .catch(() => {
          window.open(url, '_blank');
        })
        .finally(() => hide());
      api.recordDownloadCount({ ids: [fileId] });
    },
    async handleDeletePicture(id) {
      this.loading = true;

      api
        .deleteMyPicStore(id)
        .then(({ code, message }) => {
          if (code === 200) {
            this.$message.success('删除成功');
            if (this.picList.length === 1 && this.pagination.current > 1) {
              this.pagination.current -= 1;
            }
            this.getPicList();
          } else {
            this.$message.error(message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getPicList();
    },
  },
};
</script>

<style lang="less" scoped>
.personal-picStore {
  margin: 20px;
  padding: 30px;
  min-height: 600px;
  background-color: #fff;
}

.pic-line {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.picture-card {
  position: relative;
  border: 1px solid #000;
}

.img-wrap {
  position: relative;
  width: 100%;
  height: 20vh;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  background-size: contain;
  background-position: center center;
  background-color: #000;
}

.btn-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid rgb(233, 233, 233);
  padding: 10px 30px;
  background: #f2f4f5;
}

.tips {
  height: 200px;
  line-height: 200px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}
</style>
