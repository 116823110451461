import request from '@/utils/request';
import { BFZ_BFF, AFT_CONTENT } from '@/constant/api';

export default {
  // 查询图片列表
  getXhsPicList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/dam/folder/page',
      method: 'post',
      data,
    });
  },
  // 获取图片列表颜色列表
  getPictureColorList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/dam/folder/color/blocks',
      method: 'post',
      data,
    });
  },
  // 获取图片列表创建人列表
  getPictureCreatorList() {
    return request({
      baseURL: BFZ_BFF,
      url: '/matrix/manager/picture/lib/listCreator',
      method: 'get',
    });
  },
  // 获取图片品牌目录
  getPictureBrandList() {
    return request({
      baseURL: BFZ_BFF,
      url: '/dam/folder/initPrincipalFolder',
      method: 'post',
    });
  },
  // 获取品牌管理列表
  getBrandManageList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/principal/page',
      method: 'get',
      params,
    });
  },
  // 获取图片文件夹树结构
  getPictureFolderTree(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/dam/folder/tree',
      method: 'get',
      params,
    });
  },
  // 新增文件夹
  addPictureFolder(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/dam/folder/add',
      method: 'post',
      data,
    });
  },
  // 文件夹重命名
  renamePictureFolder(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/dam/folder`,
      method: 'patch',
      data,
    });
  },
  // 删除文件夹
  deletePictureFolder(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/dam/folder/${id}`,
      method: 'delete',
    });
  },
  // 文件夹移动
  movePictureFolder(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/dam/folder/move',
      method: 'patch',
      data,
    });
  },
  // 存入我的图片库列表
  handleBatchToMyPicStore(data) {
    return request({
      baseURL: AFT_CONTENT,
      url: `/design/userImages/save`,
      method: 'post',
      data,
    });
  },
  // 通过文件id列表删除文件列表
  handleDeletePicture(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/dam/file/delete`,
      method: 'post',
      data,
    });
  },
  //跳转图文编辑器前，保存所选图片链接，有效期30分钟，结果返回跳转的id
  saveImageList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/graphic-text-template/before-jump-editor',
      method: 'post',
      data,
    });
  },
  // 产品列表(新-车系列表)
  getNewTypeList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/matrix/manager/picture/lib/series/optional/v1',
      method: 'get',
      params,
    });
  },
  // 上传图片至图片库
  uploadPictureToStore(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/dam/file/confirm',
      method: 'post',
      data,
    });
  },
  // 我的图库列表
  getMyPicStoreList(params) {
    return request({
      baseURL: AFT_CONTENT,
      url: '/design/userImages/page',
      method: 'get',
      params,
    });
  },
  // 删除我的图库
  deleteMyPicStore(id) {
    return request({
      baseURL: AFT_CONTENT,
      url: `/design/userImages/${id}`,
      method: 'delete',
    });
  },
  // 记录图片下载次数
  recordDownloadCount(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/dam/file/download',
      method: 'post',
      data,
    });
  },
};
